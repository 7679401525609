import React from 'react';
import './YouTubePage.css';

function YouTubePage() {
  return (
    <div className="youtube-page">
      <h1>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"
          alt="YouTube"
          className="youtube-logo" // Apply the class here
        />
      </h1>
      <div className="video-grid">
        {/* First Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/Oh5Zfo4dQnk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-(둘째, 상황 시리즈) 나를 이리로 보낸 자는 하나님이시라 <br /><span className="eng-text">(10/13/2024)</span>
          </p>
        </div>

        {/* Second Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/gc8qNwVDYzk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-(첫째, 상황 시리즈) 내가 자족하기를 배웠노니 <br /><span className="eng-text">(10/6/2024)</span>
          </p>
        </div>

        {/* Third Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
             src="https://www.youtube.com/embed/_3SybKdjZS8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-너희는 세상의 소금과 빛이다 <br /><span className="eng-text">(9/29/2024)</span>          
          </p>
        </div>
      </div>
    </div>
  );
}

export default YouTubePage;
