import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './NewsDetail.css';
import candles from './candles.jpg'; // Importing the image of candles

const announcements = {
  1: {
    date: '2024-06-2',
    content: '1. 예배 시간 15분 전에 나오셔서 기도로 예배를 준비하시길 바랍니다.<br />' +
             '2. 6월 달에 생일 맞으신 분들을 주 이름으로 축복합니다.<br />' +
             '이택자집사, 성순중권사, Lauren Suh, Grace Kim<br />' +
             '3. 수요일 QT와 목요일 성경공부가 오전 11시에 있습니다.<br />' +
             '4. 오늘부터 예배 전에 QT (말씀 묵상)의 시간을 갖겠습니다.<br />' +
             '5. 이학주장로님 내외분께서 2주간 한국 여행 중이십니다.<br />' +
             '6. 강권하여 주님의 몸 된 전을 채우고 세우는 일에 열심을 다합시다.<br />' +
             '7. 구역 모임의 활성화를 위해 모든 분들의 적극적인 참여를 바랍니다.<br />' +
             '8. 모든 모임에 적극적인 동참을 부탁드립니다.<br />' +
             '9. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',
  },
  2: {
    date: '2024-06-09',
    content: '1. 예배 시간 전에 나오셔서 기도로 예배를 준비하시길 바랍니다.<br />' +
         '2. 예배 전에 QT (말씀 묵상)의 시간에 꼭 참석해 주시길 바랍니다.<br />' +
         '3. 다음 주는 Father&#39;s Day입니다.<br />' +
         '4. 이잭키성도님의 남편이신 이철용성도님께서 예배에 동참해 주셨습니다.<br />' +
         '5. 김갑석목사님 내외분께서 버지니아 따님 댁을 방문 중이십니다.<br />' +
         '6. 이학주장로님 내외분께서 내일 새벽에 한국에서 돌아오십니다.<br />' +
         '7. 오늘 친교 시간에 PTA 모임을 갖겠습니다.<br />' +
         '8. 목요일 11시에 줌으로 있는 성경 공부에 참석을 부탁드립니다.<br />' +
         '9. 강권하여 주님의 몸 된 전을 채우고 세우는 일에 열심을 다합시다.<br />' +
         '10. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',
  },

  3: {
    date: '2024-06-16',
    content: '1. 예배 시간 전에 나오셔서 기도로 예배를 준비하시길 바랍니다.<br />' +
         '2. 수요예배를 매달 첫째와 둘째 수요일 본당에서 11시에 드립니다.<br />' +
         '3. 오늘은 Father\'s Day입니다. 이영미집사님께서 친교를 제공하셨습니다.<br />' +
         '4. 금요일(21일)에 믿음 구역 모임이 이영미집사님 댁에서 11시 30분에 있습니다. 구역원들은 한 분도 빠짐없이 동참해 주시길 부탁드립니다.<br />' +
         '5. 김갑석목사님 내외분, 이학주장로님 내외분께서 여행에서 돌아오셨습니다.<br />' +
         '6. 오늘 친교 후에 남전도회 총회가 있고 여전도회는 30일에 있습니다.<br />' +
         '7. 박성신집사님의 인도로 박창대성도님께서 예배에 동참하셨습니다.<br />' +
         '8. 목요일 11시에 줌으로 있는 성경 공부에 참석을 부탁드립니다.<br />' +
         '9. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',
  },
  4: {
    date: '2024-06-23',
    content: '1. 예배 시간 전에 나오셔서 기도로 예배를 준비하시길 바랍니다.<br />' +
         '2. 수요예배를 매달 첫째와 둘째 수요일 본당에서 11시에 드립니다.<br />' +
         '3. 지난 주 새 임원 선출을 위한 남전도회 총회가 있었습니다.<br />' +
         '회장: 이학주장로, 총무: 김보람집사<br />' +
         '4. 다음 주(30일)는 여전도회 총회가 친교 후에 있습니다.<br />' +
         '5. 교우 동정<br />' +
         '명기 워렌 권사님께서 한 주간 여행을 떠나셨습니다.<br />' +
         '이학주장로님 내외분께서 올랜도의 따님 댁을 방문 중이십니다.<br />' +
         '김보람집사님 가족은 뉴욕을 방문 중이십니다.<br />' +
         '6. 강권하여 주님의 몸된 전을 채우고 세움이 성도의 사명입니다.<br />' +
         '7. 목요일 11시에 줌으로 있는 성경 공부에 참석을 부탁드립니다.<br />' +
         '8. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',

  },

  5: {
    date: '2024-06-30',
    content: '1. 11시 예배 전 10시 50분부터 찬양을 시작합니다.<br />' +
         '2. 로마서 10:10, “사람이 마음으로 믿어 의에 이르고 입으로 시인하여 구원에 이르느니라”<br />' +
         '3. 새 임원 선출을 위한 여전도회 총회가 친교 후에 있습니다.<br />' +
         '4. 백홍열박사님, 방혜숙권사님 내외분께서 한국에서 방문하셨습니다.<br />' +
         '강난희권사님께서 탈라하시에서 방문하셨습니다.<br />' +
         '5. 이철용성도, 이잭키집사 내외분께서 우리 교회 등록 교인이 되셨습니다.<br />' +
         '이철용성도님 내외분께서 2주간 따님을 만나시러 여행을 가셨습니다.<br />' +
         '6. 친교 시간에 지정석은 따로 없습니다.<br />' +
         '자리를 옮기시며 두루두루 친교의 시간을 가져 주시길 부탁드립니다.<br />' +
         '7. 강권하여 주님의 몸 된 전을 채우고 세움이 성도의 사명입니다.<br />' +
         '8. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',
  },

  6: {
    date: '2024-07-07',
    content: '1. 11시 예배 전 10시 50분부터 찬양을 시작합니다.<br />' +
         '2. 이영미집사님의 시아버님께서 지난 화요일(2일)에 소천하셨습니다.<br />' +
         '하나님의 위로하심이 집사님과 모든 유가족들 위에 임하길 기도합니다.<br />' +
         'Viewing은 목요일(11일)에 있고 장례식은 금요일(12일)에 있습니다.<br />' +
         '3. 남 전도회 회장: 이학주장로, 총무: 김보람집사<br />' +
         '여 전도회 회장: 전혜란집사, 부회장: 한명환권사, 총무: 이영미집사<br />' +
         '4. 7월달 생일 맞으신 분입니다: 김인규장로<br />' +
         '5. 이학주장로님 내외분께서 올랜도 따님 댁을 방문 중이십니다.<br />' +
         '이철용성도님 내외분께서 여행 중이신데 내일 돌아오십니다.<br />' +
         '6. 친교 시간에 지정석은 따로 없습니다.<br />' +
         '자리를 옮기시며 두루두루 친교의 시간을 가져 주시길 부탁드립니다.<br />' +
         '7. 강권하여 주님의 몸 된 전을 채우고 세움이 성도의 사명입니다.<br />' +
         '8. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',
  },

  7: {
    date: '2024-07-14',
    content: '1. 11시 예배 전 10시 50분부터 찬양을 시작합니다.<br />' +
         '10시 45분까지 나오셔서 기도로 예배를 준비해 주시길 부탁드립니다.<br />' +
         '2. 이영미집사님의 시아버님의 Viewing과 Funeral에 참석하신 모든 분들께 감사드립니다.<br />' +
         '3. Mr. Rick Lamb과 전혜란집사님 내외분께서 Funeral에 참석하신 분들에게 점심 식사 제공을 하셨습니다.<br />' +
         '하나님께서 갚아주실 줄 믿습니다.<br />' +
         '4. Elder Ric. Strickland 내외분께서 건강상의 이유로 예배 참석을 못하셨습니다.<br />' +
         '이번 주 예정이었던 믿음 구역 모임도 연기를 하겠습니다.<br />' +
         '5. 성가대와 새 교우 환영회를 이번 주 금요일(19일)에 갖겠습니다.<br />' +
         '시간과 장소를 카톡을 통해 알려드리겠습니다. 참석을 부탁드립니다.<br />' +
         '6. 친교 시간에 지정석은 따로 없습니다.<br />' +
         '자리를 옮기시며 두루두루 친교의 시간을 가져 주시길 부탁드립니다.<br />' +
         '7. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',
  },

  8: {
    date: '2024-07-21',
    content: '1. 플로리다 말씀교회는 예수님만 사랑하고 자랑합니다.<br />' +
         '2. 믿음 구역 모임이 Mr. Rick Lamb와 전혜란집사님 댁에서 금요일(26일) 11시 30분에 있습니다.<br />' +
         '3. 구역 모임 때 앞으로는 Potluck으로 동참해 주시길 부탁드립니다.<br />' +
         '4. John Brenner집사님의 빠른 회복을 위해 기도를 부탁드립니다.<br />' +
         '5. 지난 주에 이잭키집사님의 인도로 한 가족이 방문해 주셨습니다.<br />' +
         'Mr. John Well, 김미숙성도, 토니성도, 센디성도<br />' +
         '6. 박성신집사님께서 여선교회 총무로 섬겨주시겠습니다.<br />' +
         '7. 여선교회 주최로 노방전도가 토요일(27일) 11시에 롯데마트에서 있습니다.<br />' +
         '전도 후에는 1시에 아시안 부페에서 여선교회 친목과 함께 새 회원 환영회가 있습니다.<br />' +
         '모든 여선교회원들의 참여를 바랍니다.<br />' +
         '8. 친교 시간에 지정석은 따로 없습니다.<br />' +
         '9. 수요 말씀 묵상의 시간과 목요일 성경 공부에 많은 참여를 바랍니다.<br />' +
         '10. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',
  },


  9: {
    date: '2024-07-28',
    content: '1. 플로리다 말씀교회는 예수님만 사랑하고 자랑합니다.<br />' +
         '2. 8월 둘째 주일 (11일)에 교회 창립 11주년 감사 예배를 드립니다.<br />' +
         '3. 여전도회 주관으로 토요일(27일)에 롯데마트에서 있었던 전도에 동참해 주신 모든 분들께 감사드립니다.<br />' +
         '김온직장로님께서 전도에 동참하신 분들께 점심을 대접해 주셨습니다.<br />' +
         '4. 본당에서 자리를 앞뒤좌우로 넓게 앉아 주시길 부탁드립니다.<br />' +
         '5. John Brenner집사님과 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
         '6. 교제의 시간마다 정성을 다해 준비한 음식으로 섬겨주시는 분들께 감사드립니다.<br />' +
         '7. 김쥬디 학생이 학업을 위해 떠납니다. 격려해 주시길 부탁드립니다.<br />' +
         'The Ohio State University College of Medicine<br />' +
         '8. 친교 시간에 지정석은 따로 없습니다.<br />' +
         '9. 수요 말씀 묵상의 시간과 목요일 성경 공부에 많은 참여를 바랍니다.<br />' +
         '10. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',

  },

  10: {
    date: '2024-08-04',
    content: '1. 예배 10분 전까지 나오셔서 기도와 찬양으로 예배를 준비하시길 바랍니다.<br />' +
         '2. 다음 주일은 교회 창립 11주년 감사 찬양 예배로 드립니다.<br />' +
         '김갑석목사님께서 말씀을 전해주십니다.<br />' +
         '교회가 재도약하는 전환점이 되도록 기도해 주시길 바랍니다.<br />' +
         '3. 8월에 생일 맞으신 분들입니다: 김윤주사모, 이정연집사<br />' +
         '4. John Brenner집사님과 김미숙성도님, 그리고 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
         '5. 지난 화요일(30일)에 3분의 집사님들(전혜란, 박성신, 이영미)께서, 전혜란집사님의 친구 댁에서 모여 주일 친교를 위해 김치를 담그셨습니다.<br />' +
         '6. 이연희집사님께서 비파 엑기스를 도네이션하셨습니다. 여선교회 주관으로 한 병에 10불씩 판매를 합니다. 여선교회 선교비로 사용합니다.<br />' +
         '7. 김숙수권사님께서 친교를 위해 떡을 제공해 주셨습니다.<br />' +
         '8. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',

  },

  11: {
    date: '2024-08-11',
    content: '1. 교회 창립 11주년을 축하해주시기 위해 방문하신 분들을 주 이름으로 환영합니다. 예배 후 식탁 교제에 함께 해주시길 부탁드립니다.<br />' +
    '2. 하나님께서 주님의 피로 값을 주고 사신 플로리다 말씀교회가 예수님만 사랑하고 자랑하는, 이 지역 사회의 등불이 되도록 힘써 주시길 바랍니다.<br />' +
    '3. 교회는 구원의 방주입니다. 그리고 구원의 방주여야 합니다. 강권하여 주위 이웃들을 구원의 방주로 인도하는 사람 낚는 어부가 되어야 할 줄 믿습니다.<br />' +
    '4. 여선교회 주관으로 비파 엑기스를 판매하여 선교회비로 $240를 모았습니다. 동참해 주신 모든 분들께 감사드립니다.<br />' +
    '5. John Brenner집사님과 김미숙성도님, 그리고 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
    '6. 사랑 구역의 구역장으로 이연희집사님께서 수고하시겠습니다.<br />' +
    '7. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',

  },

  12: {
    date: '2024-08-18',
    content: '1. 예배는 10시 50분부터 찬양으로 시작을 합니다. 미리 나오셔서 기도와 찬양으로 예배에 임해주시길 바랍니다.<br />' +
    '2. 믿음 구역 모임이 금요일(23일)에 Ric. Strickland장로님 내외분 댁에서 있습니다. 시간은 11시 30분입니다.<br />' +
    '3. 이학주장로님 내외분께서 올랜도 따님 댁을 방문 중이십니다.<br />' +
    '4. 김온직장로님 내외분께서 뉴욕을 방문 중이십니다.<br />' +
    '5. John Brenner집사님과 김미숙성도님, 그리고 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
    '6. 9월 첫째 주일(1일) 예배 후에 제직회가 있습니다.<br />' +
    '7. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체',


  },
  
  13: {
    date: '2024-08-18',
    content: '1. 예배는 10시 50분부터 찬양으로 시작을 합니다. 미리 나오셔서 기도와 찬양으로 예배에 임해주시길 바랍니다.<br />' +
    '2. 다음 주일은 예배 후에 제직회로 모입니다.<br />' +
    '3. 김숙수권사님께서 화요일(27일)에 한국으로 귀국하십니다.<br />' +
    '4. 김갑석목사님께서 10월 중순까지 탬파개혁장로교회와 방주교회에서 말씀을 전하십니다.<br />' +
    '5. 9월 둘째 주일 친교 후에 본당에서 말씀에 대한 특강이 있습니다.<br />' +
    '6. John Brenner집사님과 김미숙성도님, 그리고 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
    '7. 여전도회 주관으로 금요일(30일), 10시에 롯데마트에서 노방전도가 있습니다. 많은 분들의 참여를 부탁드립니다.<br />' +
    '8. 하나님의 말씀을 청종하여 들으실 때 영혼의 양식이 될 줄 믿습니다.<br />' +
    '9. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체'

  },

  14: {
    date: '2024-09-1',
    content: '1. 오늘 예배 후에 제직회로 모입니다.<br />' +
    '2. 다음 주 친교 후에 성경공부가 있습니다.<br />' +
    '3. 9월 달 생일 맞으신 분들: 박성신집사, Issac Kim, Christopher Kim<br />' +
    '4. 김온직장로님께서 1달 반 일정으로 한국을 방문하십니다.<br />' +
    '5. 김복희권사님께서 2주간 한국을 방문하십니다.<br />' +
    '6. 김미숙성도님 내외분께서 손자 결혼식 참석을 위해 여행 중이십니다.<br />' +
    '7. 이학주장로님 내외분께서 올랜도 따님 댁을 방문 중이십니다.<br />' +
    '8. 교회 웹싸이트가 완성되었습니다.<br />' +
    '구글 검색창에 영어로 Florida Church of the Word 입력하시면 됩니다.<br />' +
    '9. John Brenner집사님과 김미숙성도님, 그리고 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
    '10. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체<br />'

  },

  15: {
    date: '2024-09-8',
    content: '1. 신령과 진정으로 예배하실 때 하나님께 영광이요 우리에게 은혜의 시간이 될 줄 믿습니다.<br />' +
    '2. 오늘 있을 예정이던 성경공부는 연기하겠습니다.<br />' +
    '3. 김복희권사, 김온직장로, 김미숙성도 내외분께서 여행 중이십니다.<br />' +
    '4. 많은 분들께서 여행 중이시고 또 여행을 계획하신 분들이 계십니다. 안전한 여행을 위해 기도해 주시길 부탁드립니다.<br />' +
    '5. 교회 웹싸이트가 완성되었습니다. 구글 검색창에 영어로 www.fcwtampa.org를 입력하시면 연결이 됩니다.<br />' +
    '6. 사랑 구역 모임 (구역장: 이연희집사)이 친교 시간에 있습니다.<br />' +
    '7. John Brenner집사님과 김미숙성도님, 그리고 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
    '8. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체<br />'

  },

  16: {
    date: '2024-09-15',
    content: '1. 신령과 진정으로 예배하실 때 하나님께 영광이요 우리에게 은혜의 시간이 될 줄 믿습니다.<br />' +
    '2. 추석을 맞아 풍성한 식탁의 교제를 위해 준비해 주신 모든 분들께 감사드립니다.<br />' +
    '3. 오늘 저녁 5시에 김진훈집사님 댁에서 사랑 구역 모임이 있습니다.<br />' +
    '전화: (813-447-0261, 주소) 2194 Lanier Rd. Zephyrhills, FL 33541<br />' +
    '4. 많은 분들께서 여행 중이시고 또 여행을 계획하신 분들이 계십니다. 안전한 여행을 위해 기도해 주시길 부탁드립니다.<br />' +
    '5. 교회 웹싸이트가 완성되었습니다. 구글 검색창에 영어로 www.fcwtampa.org를 입력하시면 연결이 됩니다.<br />' +
    '6. 강권하여 주님의 몸 된 전을 채우는데 힘써 주시길 부탁드립니다.<br />' +
    '7. John Brenner집사님과 김미숙성도님, 그리고 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
    '8. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체<br />'

  },

  17: {
    date: '2024-09-22',
    content: '1. 신령과 진정으로 예배하실 때 하나님께 영광이요 우리에게 은혜의 시간이 될 줄 믿습니다.<br />' +
    '2. 여선교회 주관으로 금요일(27) 오전 10시에 롯데마트에서 노방전도가 있습니다. 많은 분들의 참여를 부탁드립니다.<br />' +
    '3. 장명기권사님께서 내일부터 2달간 한국을 방문하십니다.<br />' +
    '4. 많은 분들께서 여행 중이시고 여행을 계획하신 분들이 계십니다. 안전한 여행을 위해 계속 기도해 주시길 부탁드립니다.<br />' +
    '5. 교회 웹싸이트가 완성되었습니다. 구글 검색창에 영어로 www.fcwtampa.org를 입력하시면 연결이 됩니다.<br />' +
    '6. 수요일 말씀 묵상의 시간(QT)이 오전 11시에 있습니다. 목요일 성경공부는 시간이 오전 10시 30분으로 변경 되었습니다.<br />' +
    '7. John Brenner집사님과 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
    '8. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체<br />'
  },

18: {
  date: '2024-09-29',
  content: '1. 신령과 진정으로 예배하실 때 하나님께 영광이요 우리에게 은혜의 시간이 될 줄 믿습니다.<br />' +
  '2. 예배 시작 전에 전화기의 소리를 낮추시거나 꺼주시길 바랍니다.<br />' +
  '3. 믿음의 성장과 성숙을 위해 성경통독을 하시길 바랍니다.<br />' +
  '4. 친교 시간에 지정석은 없습니다. 자리를 옮기시며 교제하시길 바랍니다.<br />' +
  '5. 김온직장로님과 명기워렌권사님께서 한국 여행 중이십니다.<br />' +
  '6. 교회 웹싸이트가 완성되었습니다. 구글 검색창에 영어로 www.fcwtampa.org를 입력하시면 연결이 됩니다.<br />' +
  '7. 어제 노방전도에 동참해 주신 모든 분들께 감사드립니다.<br />' +
  '8. 김미숙성도님께서 코 수술을 하실 예정인데 기도해 주시길 바랍니다.<br />' +
  '9. 몸이 불편하신 분들과 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
  '10. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체<br />'

},

19: {
  date: '2024-10-06',
  content: '1. 신령과 진정으로 예배하실 때 하나님께 영광이요 우리에게 은혜의 시간이 될 줄 믿습니다.<br />' +
  '2. 말씀을 들으실 때 다른 분들에게 방해가 되지 않도록 조심해 주시길 바랍니다.<br />' +
  '3. 10월 달 생일 맞으신 분들: 송정애권사, 김복희권사, 김순복사모, 이철용성도, 이잭키집사, Robert Means집사<br />' +
  '4. 친교 시간에 지정석은 없습니다. 자리를 옮기시며 교제하시길 바랍니다.<br />' +
  '5. 이철용성도님 댁에서 믿음 구역 모임이 목요일, 11:20분에 있습니다.<br />' +
  '전화: 813) 966-8282<br />' +
  '주소: 10212 Garden Alcove Dr. Tampa, FL 33647<br />' +
  '6. 교회 웹싸이트가 완성되었습니다. 구글 검색창에 영어로 fcwtampa.org를 입력하시면 연결이 됩니다.<br />' +
  '7. 몸이 불편하신 분들과 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
  '8. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체<br />'
},

20: {
  date: '2024-10-13',
  content: '1. 신령과 진정으로 예배하실 때 하나님께 영광이요 우리에게 은혜의 시간이 될 줄 믿습니다.<br />' +
  '2. 강력한 허리케인 가운데서도 지켜주신 하나님께 영광을 돌립니다.<br />' +
  '3. 구역모임은 25일로 연기가 되었습니다. 장소와 시간은 동일합니다.<br />' +
  '4. 김보람집사님께서 교육부 부장으로 수고해 주시겠습니다.<br />' +
  '5. 차경숙권사님께서 성도 여러분의 안부를 물으시며 특별헌금을 하셨습니다.<br />' +
  '6. 친교 시간에 지정석은 없습니다. 자리를 옮기시며 교제하시길 바랍니다.<br />' +
  '7. 금요일(18일)에 커피 브레이크 모임이 있습니다. 장소는 추후에 알려드리겠습니다. 많은 분들의 참여를 바랍니다.<br />' +
  '8. 몸이 불편하신 분들과 연로하신 분들을 위해 기도를 부탁드립니다.<br />' +
  '9. 협력 선교사: 박희성목사 (온두라스), Compassion 선교 단체<br />'
}



    
};

function AnnouncementDetail() {
  const { id } = useParams();
  const announcement = announcements[id];

  if (!announcement) {
    return <div>Announcement not found.</div>;
  }

  return (
    <div className="announcement-detail-page">
      <div className="header-image-container">
        <img src={candles} alt="Candles" className="header-image" />
        <div className="header-text">
          <h1>교회소식</h1>
          <p>Announcements</p>
        </div>
      </div>

      <div className="announcement-detail">
        <h1>{announcement.title}</h1>
        <p><strong>게시일: </strong>{announcement.date}</p>
        {/* Using dangerouslySetInnerHTML to properly render the HTML line breaks */}
        <p dangerouslySetInnerHTML={{ __html: announcement.content }} />
        <Link to="/news" className="back-button">목록보기</Link>
      </div>
    </div>
  );
}

export default AnnouncementDetail;
